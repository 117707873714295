import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../../components/images/imageCoaching.js'
import ImageDevelopement from '../../components/images/imageDevelopement.js'
import ImageClimat from '../../components/images/imageClimat.js'
import ImageChange from '../../components/images/imageChange.js'
import ImageConflit from '../../components/images/imageConflit.js'
import ImageLeft9 from '../../components/images/imageLeft9.js'

const Equipes = () => (
  <Layout>
    <SEO title="Dénouer rapidement les tensions et conflits | Gestion Mieux-Être" />
    <PageHeader
        header="Dénouer rapidement les tensions et conflits"
        text="Les tensions et confits dans le milieu de travail ont de sérieuses incidences tant pour les parties directement impliquées que pour tous les autres membres de vos équipes. La vaste majorité des problématiques de climat de travail sont occasionnées par des difficultés relationnelles.   "
        quote="C’est l’ignorance, et non la connaissance, qui dresse les hommes les uns contre les autres<span>- Kofi Annan</span>"
        background="tensions" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Vous pouvez compter sur nous afin de :</p>
        <div className="flex-container pad2">

            <div className="half">
                <ul className="success">
                    <li>Outiller vos gestionnaires quant à la prévention et la gestion des tensions au sein de leur équipe</li>
                    <li>Dénouer des relations tendues / conflictuelles</li>
                </ul>
            </div>
            <div className="half"><ImageLeft9 /></div>
        </div>
        <p class="service-presentation2">Comment aller au-delà d’un simple cessez-le-feu?  Nous avons la conviction qu’il n’y a pas qu’une seule bonne réponse afin de résoudre un conflit !  </p>
        <p class="service-presentation2">Nous rassemblons les gens et stimulons leur capacité à aborder positivement leurs désaccords. Avec la mise en place d’un dialogue constructif, il est possible de créer de nouvelles réalités, un mieux-être pour tous.</p>
        <p class="service-presentation2">Nous vous aidons à créer un environnement systémique qui encourage une réelle concertation. L’écoute et la franchise des échanges sont au cœur de notre pratique professionnelle.  Notre approche:  poser de franches questions … et écouter!</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>

    </div>
  </Layout>
)

export default Equipes
